.ps-logo {
  max-width: 170px;

  @media only screen and(max-width: 568px) {
    max-width: 140px;
  }

  &_mobile {
    max-width: 140px;
  }
}

.cc-1 .dropdown-item {
  word-break: break-word !important;
  white-space: pre-wrap !important;
}

.home_video-text {

  h3 {
    font: {
      size: 2.5rem;
    }
  }

  @media only screen and(max-width: 992px) {
    padding-top: 60px;
    h3 {
      font-size: 30px !important;
    }
  }

  @media only screen and(max-width: 768px) {
    h3 {
      font-size: 24px !important;
    }
  }

  @media only screen and(max-width: 558px) {
    h3 {
      font-size: 20px !important;
    }
  }
}
